.properties-simple-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 25px;

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
	}
}

.properties.type-properties {
	margin: 0;
	position: relative;
	box-shadow: var(--rentfetch-box-shadow);
	background-color: var(--rentfetch-white);

	.overlay {
		position: absolute;
		inset: 0;
		cursor: pointer;
		z-index: 5;
		transition: 0.5s box-shadow ease;
	}

	&:hover {
		.overlay {
			box-shadow: var(--rentfetch-box-shadow-hover);
		}

		.post-edit-link {
			opacity: 1;
		}
	}

	.property-single-image-wrap {
		height: 0;
		padding-top: 66.7%;
		position: relative;

		img.property-single-image {
			object-fit: cover;
			width: 100%;
			height: 100%;
			position: absolute;
			inset: 0;
		}
	}

	.property-content {
		padding: 15px;

		h3 {
			margin-bottom: 5px;
			font-size: var(--rentfetch-font-size-large);
			color: var(--rentfetch-dark);
		}

		p {
			color: var(--rentfetch-dark);
			font-size: var(--rentfetch-font-size-medium);
			line-height: 1.2;
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	a.post-edit-link {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 5px 10px;
		background-color: var(--rentfetch-dark);
		text-decoration: none;
		line-height: 1;
		z-index: 10;
		opacity: 0;
		margin: 0;
		font-size: var(--rentfetch-font-size-small);
		color: var(--rentfetch-white);
		transition: 0.3s all ease;

		&:hover {
			background-color: var(--rentfetch-accent);
			color: var(--rentfetch-white);
		}
	}
}
