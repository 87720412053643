body a.rentfetch-button {
	text-decoration: none;
	line-height: 1;
	position: static;
	padding: 15px 25px;
	font-size: var(--rentfetch-font-size-medium);
	background-color: var(--rentfetch-accent);
	color: var(--rentfetch-white);
	border: none;
	transition: 0.3s all ease;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;

	&.rentfetch-button-small {
		font-size: var(--rentfetch-font-size-small);
		padding: 10px 20px;
	}

	&.rentfetch-button-no-highlight {
		background-color: var(--rentfetch-base);
		color: var(--rentfetch-dark);

		&:hover {
			background-color: var(--rentfetch-dark);
			color: var(--rentfetch-white);
		}
	}

	&:hover,
	&:focus {
		color: var(--rentfetch-white);
		background-color: var(--rentfetch-accent-active);
	}
}
