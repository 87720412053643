.floorplans-loop,
.floorplans-simple-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 25px;

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mobile {
		grid-template-columns: 1fr;
	}

	.entry,
	.hentry {
		padding: 0;
		margin: 0;
		box-shadow: var(--rentfetch-box-shadow);
		transition: 0.5s box-shadow ease;
		position: relative;
		background-color: var(--rentfetch-white);
		overflow: hidden; /* Hide overflow content */
		word-break: break-word; /* To prevent long words from overflowing */
	}

	.post-edit-link {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 3px 8px;
		background-color: var(--rentfetch-black-90-percent);
		color: var(--rentfetch-white);
		font-size: var(--rentfetch-font-size-small);
		text-decoration: none;
		transition: 0.3s all ease;
		z-index: 15;
		line-height: 1;
		margin: 0;
		opacity: 0;

		&:hover {
			background-color: var(--rentfetch-black-80-percent);
		}
	}

	.floorplan-content {
		margin-bottom: -15px;
	}

	.floorplan-details {
		padding: 15px;
	}

	.floorplan-single-image-wrap,
	.floorplan-image-slide {
		// width: 100%;
		height: 0;
		padding-top: 66.7%;
		position: relative;
		margin: 15px 15px 0;
		// max-width: 100%;

		img.floorplan-single-image,
		img.floorplan-image {
			object-fit: contain;
			width: 100%;
			height: 100%;
			position: absolute;
			inset: 0;
		}
	}

	h3 {
		font-size: var(--rentfetch-font-size-large);
		color: var(--rentfetch-dark);
	}

	p {
		font-size: var(--rentfetch-font-size-medium);
		color: var(--rentfetch-dark);
		line-height: 1.2;
	}

	.floorplan-content {
		h3,
		p {
			margin-bottom: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1; /* number of lines to show */
			line-clamp: 1;
			-webkit-box-orient: vertical;
		}
	}

	.floorplan-attributes {
		line-height: 1;
		// margin-bottom: 15px;

		p {
			display: inline-block;
			padding: 0 0.8em;
			margin: 0 0 15px 0;
			position: relative;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
				margin: 0 0 15px 0;

				&:after {
					display: none;
				}
			}

			&:after {
				height: 50%;
				position: absolute;
				content: '';
				width: 1px;
				background-color: var(--rentfetch-black-20-percent);
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	p.specials {
		position: absolute;
		top: 15px;
		left: 15px;
		padding: 5px 10px 5px 30px;
		background-color: var(--rentfetch-gold-pale);
		z-index: 5;
		font-size: var(--rentfetch-font-size-small);
		border-radius: 3px;
		color: var(--rentfetch-dark);
		margin: 0;

		&:before {
			content: '\f155';
			font-family: 'dashicons';
			color: var(--rentfetch-gold);
			font-size: var(--rentfetch-font-size-medium);
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.floorplan-availability {
		margin-top: 10px;
		padding-top: 15px;
		border-top: 1px solid rgba(0, 0, 0, 0.07);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	p.pricing {
		font-size: var(--rentfetch-font-size-large);
		font-weight: bold;
		margin: 0;
	}

	p.availability {
		font-size: var(--rentfetch-font-size-small);
		font-style: italic;
		margin: 0;
	}

	.overlay-link {
		position: absolute;
		inset: 0;
		transition: 0.3s all ease;
		z-index: 10;
	}

	.buttons-outer {
		position: absolute;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--rentfetch-white-85-percent);
		opacity: 0;
		pointer-events: none;
		transition: 0.3s all ease;
		z-index: 12;

		.buttons-inner {
			width: 75%;
			max-width: 300px;

			.rentfetch-button {
				display: block;
				text-align: center;
				margin-bottom: 5px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.entry:hover,
	.hentry:hover {
		box-shadow: var(--rentfetch-box-shadow-hover);

		.buttons-outer {
			opacity: 1;
			pointer-events: all;
		}

		.post-edit-link {
			opacity: 1;
		}
	}
}

.floorplan-search-filters {
	display: none;
}
