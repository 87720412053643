form.minisearch {
	padding: 15px;
	background-color: var(--rentfetch-base);
	display: inline-block;
	border-radius: 3px;

	@include mobile {
		width: 100%;
	}

	.filters-wrap {
		margin-bottom: 0;

		#featured-filters {
			gap: 8px 0;

			@include mobile {
				grid-template-columns: 1fr;
			}

			fieldset {
				@include mobile {
					display: block;
					margin: 0;
					width: 100%;
				}

				> button {
					@include mobile {
						width: 100%;
						text-align: left;
						background-color: rgba(black, 0.03);
						border-radius: 3px;
						&::after {
							position: absolute;
							right: 15px;
						}
					}
				}
			}

			fieldset.text-based-search {
				width: auto;

				@include notmobile {
					margin-right: 0;
				}
			}

			button#minisearch-submit {
				margin-left: 25px;
				position: relative;
				padding: 15px 43px 15px 20px;
				border: none;
				border-radius: 3px;
				background-color: var(--rentfetch-accent);
				color: white;
				cursor: pointer;

				@include notdesktop {
					margin: 0;
				}

				@include mobile {
					padding: 15px;
				}

				&:hover {
					background-color: var(--rentfetch-accent-active);
					color: white;
				}

				&:after {
					font-family: 'dashicons';
					content: '\f344';
					margin-left: 5px;
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);

					@include mobile {
						display: none;
					}
				}
			}
		}
	}
}
