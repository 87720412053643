.rent-fetch_page_rentfetch-shortcodes,
.container.shortcodes {

	span.shortcode {
		background-color: white;
		border: 1px solid rgba(black, 0.12);
		box-shadow: 2px 2px 5px 0 rgba(black, 0.07);
		cursor: pointer;
		display: inline-block;
		margin: 0 5px 10px 0;
		padding: 8px 15px;
		position: relative;
		transition: 0.3s all ease;
		transition: 0.3s all ease;

		&:hover {
			box-shadow: 2px 2px 5px 0 rgba(black, 0.2);
		}

		&:before {
			transition: 0.3 sll all ease;
			opacity: 0;
		}
	}

	span.shortcode.copied {
		&:before {
			content: url(../images/copied.svg);
			position: absolute;
			inset: 0;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			padding-top: 3px;
		}
	}

	h2 {
		font-size: 20px;
		margin-top: 0;
	}
}

#rent-fetch-shortcodes-page {
	.separator {
    border-bottom: 1px solid #E6E6E6;
    width: 100%;
  }
}