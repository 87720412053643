#map {
	width: 100%;
	height: 500px;
	margin-bottom: 30px;

	// google's close button
	.gm-style-iw-chr {
		display: none;
	}

	.gm-style-iw,
	.gm-style-iw-d {
		padding: 0 !important;
		margin: 0 !important;
	}

	.map-property-popup {
		padding: 15px 0 0 15px;
	}
}

.filters-wrap {
	margin-bottom: 30px;
}
