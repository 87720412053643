/* ==========================================================================
   Styles for columns in the #response div (used in the maps shortcode)
   ========================================================================== */

.properties-loop {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	padding: 0;

	.entry,
	.hentry {
		background-color: var(--rentfetch-white);
		margin: 0;
		box-shadow: var(--rentfetch-box-shadow);
		transition: 0.5s box-shadow ease;
		cursor: pointer;
		position: relative;
		overflow: visible;

		&:hover {
			box-shadow: var(--rentfetch-box-shadow-hover);

			.post-edit-link {
				opacity: 1;
			}
		}

		&.active,
		&:focus {
			transition: 0.75s all ease;
			box-shadow: var(--rentfetch-box-shadow-active);
		}
	}

	.overlay {
		z-index: 5;
		position: absolute;
		inset: 0;
		cursor: pointer;
	}

	.property-single-image-wrap {
		width: 100%;
		height: 0;
		padding-top: 66.7%;
		position: relative;

		img.property-single-image {
			object-fit: cover;
			width: 100%;
			height: 100%;
			position: absolute;
			inset: 0;
		}
	}

	h3,
	p {
		color: var(--rentfetch-dark);
	}

	h3,
	p.property-location {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.property-attributes {
		line-height: 1;

		p {
			display: inline-block;
			margin-right: 10px;
			margin-bottom: 10px;
			font-size: var(--rentfetch-font-size-small);
		}

		// p.square-feet {
		// 	display: block;
		// }
	}

	.property-availability {
		margin-top: 5px;
		padding-top: 15px;
		border-top: 1px solid rgba(black, 0.07);
		display: flex;
		flex: 0 1 auto;
		justify-content: space-between;
		align-items: center;
	}

	.property-details {
		padding: 15px;
	}

	a.post-edit-link {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 5px 10px;
		background-color: var(--rentfetch-dark);
		text-decoration: none;
		line-height: 1;
		z-index: 10;
		opacity: 0;
		margin: 0;
		font-size: var(--rentfetch-font-size-small);
		color: var(--rentfetch-white);
		transition: 0.3s all ease;

		&:hover {
			background-color: var(--rentfetch-accent);
			color: var(--rentfetch-white);
		}
	}

	p.specials {
		position: absolute;
		top: 15px;
		left: 15px;
		padding: 5px 10px 5px 30px;
		background-color: var(--rentfetch-gold-pale);
		z-index: 5;
		font-size: var(--rentfetch-font-size-small);
		border-radius: 3px;
		color: var(--rentfetch-dark);
		margin: 0;

		&:before {
			content: '\f155';
			font-family: 'dashicons';
			color: var(--rentfetch-gold);
			font-size: var(--rentfetch-font-size-medium);
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	h3 {
		margin: 0 0 10px;
		font-size: var(--rentfetch-font-size-large);
	}

	p {
		font-size: var(--rentfetch-font-size-medium);
		margin: 0 0 10px;
		line-height: 1.2;

		&:last-child {
			margin-bottom: 0;
		}
	}

	p.rent {
		font-size: var(--rentfetch-font-size-medium);
		font-weight: bold;
	}

	p.rent,
	p.availability {
		margin: 0;
	}

	p.availability {
		font-size: var(--rentfetch-font-size-small);
		font-style: italic;
	}
}

.results-count {
	margin-bottom: 20px;
	font-size: var(--rentfetch-font-size-medium);
	line-height: 1;

	#properties-results-count-number {
		font-weight: bold;
	}
}
