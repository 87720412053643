.more-properties-slider.blaze-slider {
	--slides-to-show: 3;

	.blaze-track-container {
		padding: 12px !important;
		margin: -12px !important;
	}

	.entry {
		.overlay {
			transition: box-shadow 0.3s ease-in-out;
		}
	}

	.entry:hover {
		.overlay {
			box-shadow: 0 0 0 5px var(--rentfetch-black-10-percent);
		}
	}
}

@media (max-width: 900px) {
	.more-properties-slider.blaze-slider {
		--slides-to-show: 2;
	}
}

@media (max-width: 500px) {
	.more-properties-slider.blaze-slider {
		--slides-to-show: 1;
	}
}
