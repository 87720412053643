.floorplangrid {
	a.button {
		// padding: 8px 20px;
		// font-size: 14px;
		background-color: $dark;
		color: white;
		border: none;
		text-decoration: none;
		transition: 0.3s all ease;
		border-radius: 3px;
		margin: 4px;
		display: inline-block;

		@include mobile {
			display: block;
			width: 100%;
			text-align: center;
			margin: 4px 0;
		}

		&:hover,
		&:active,
		&:focus {
			text-decoration: none;
			color: white;
		}
	}
}
