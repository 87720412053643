table.unit-details-table {
	margin-top: 25px;
	margin-bottom: 0;
	border: none;
	width: 100%;
	border-spacing: 0;

	@include notdesktop {
		display: none;
	}

	tbody {
		border: none;
	}

	th {
		font-size: var(--rentfetch-font-size-small);
		font-weight: bold;
		text-align: left;
		border: none;
		border-bottom: 1px solid var(--rentfetch-black-10-percent);
		padding: 5px 0;

		@include mobile {
			font-size: var(--rentfetch-font-size-small);
		}
	}

	td {
		font-size: var(--rentfetch-font-size-small);
		text-align: left;
		border: none;
		border-bottom: 1px solid var(--rentfetch-black-10-percent);
		padding: 5px 0;

		@include mobile {
			font-size: var(--rentfetch-font-size-small);
		}
	}

	td.unit-buttons {
		text-align: right;
	}

	tr:last-child {
		border-bottom: none;

		td {
			border-bottom: none;
		}
	}

	tbody {
		border-bottom: none;
	}
}
