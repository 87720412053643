#single-property-map {
	height: 60vh;
	width: 100%;

	.gm-ui-hover-effect {
		display: none !important;
	}

	p.title {
		font-weight: bold;
		margin-bottom: 0;
		font-size: var(--rentfetch-font-size-large);
	}

	p.location {
		font-size: var(--rentfetch-font-size-small);
	}

	.map-marker {
		padding: 15px 0 0 15px;
	}

	// google's close button
	.gm-style-iw-chr {
		display: none;
	}

	.gm-style-iw,
	.gm-style-iw-d {
		padding: 0 !important;
		margin: 0 !important;
	}
}
