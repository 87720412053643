body.single-floorplans {
	.site-content {
		display: block;
	}

	.current-floorplan-info {
		display: grid;
		grid-template-columns: 4fr 6fr;
		gap: 60px;
		align-items: center;

		@include notdesktop {
			grid-template-columns: 1fr;
			gap: 20px;
		}

		.images-column {
			img {
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 500px;
			}
		}

		p.specials {
			position: relative;
			padding: 5px 10px 5px 30px;
			background-color: var(--rentfetch-gold-pale);
			z-index: 5;
			font-size: var(--rentfetch-font-size-small);
			border-radius: 3px;
			color: var(--rentfetch-dark);
			width: auto;
			display: inline-block;

			&:before {
				content: '\f155';
				font-family: 'dashicons';
				color: var(--rentfetch-gold);
				font-size: var(--rentfetch-font-size-medium);
				position: absolute;
				left: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		p.pricing {
			font-size: var(--rentfetch-font-size-large);
		}

		.floorplan-attributes {
			line-height: 1;
			// margin-bottom: 15px;

			p {
				font-size: var(--rentfetch-font-size-large);
				display: inline-block;
				padding: 0 0.8em;
				margin: 0 0 15px 0;
				position: relative;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;

					&:after {
						display: none;
					}
				}

				&:after {
					height: 50%;
					position: absolute;
					content: '';
					width: 1px;
					background-color: var(--rentfetch-black-20-percent);
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		.floorplan-buttons {
			margin-top: 15px;

			a {
				margin: 0 5px 5px 0;
			}
		}

		.floorplan-description {
			margin-top: 25px;

			p,
			ul,
			ol {
				margin-bottom: 5px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.floorplan-images-slider {
		.floorplan-image-slide {
			text-align: center;
		}

		.blaze-buttons {
			@include desktop {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				margin-top: 0;
			}
		}
	}

	// Defaults for the section containers.
	.single-floorplans-container-outer {
		margin: 0 calc(50% - 50vw);
		max-width: 100vw;
		width: 100vw;
		padding: 60px 25px;

		@include notdesktop {
			padding: 25px;
		}

		&:nth-child(even) {
			background-color: var(--rentfetch-base);
		}

		.single-floorplans-container-inner {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
		}
	}

	.container-current-floorplan-info {
	}

	.container-similar-floorplans {
		.floorplans-loop {
			@include desktop {
				margin-top: 30px;
			}
		}
	}

	.container-units {
		.unit-details-table {
			margin-top: 30px;
		}
	}

	.container-tour {
		text-align: center;

		iframe {
			margin-top: 15px;
		}
	}

	h2 {
		text-align: center;

		@include mobile {
			text-align: left;
		}
	}
}
