.tour-link-wrapper {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 15;

	a {
		color: var(--rentfetch-white);
		background-color: var(--rentfetch-dark);
		font-size: var(--rentfetch-font-size-medium);
		text-decoration: none;
		line-height: 1;
		position: relative;
		display: inline-block;
		text-align: center;
		height: 34px;
		width: 34px;
		border-radius: 3px;
		transition: 0.3s all ease;

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 20px;
			height: 20px;
			transform: translateX(-50%) translateY(-50%);
			background-color: white;
			border-radius: 100%;
			z-index: 2;
			transition: 0.3s all ease;
		}

		&:before {
			font-size: var(--rentfetch-font-size-medium);
			content: '\f522';
			font-family: 'dashicons';
			color: var(--rentfetch-dark);
			z-index: 3;
			line-height: 1;
			text-align: center;
			position: absolute;
			left: 55%;
			top: 53%;
			width: 20px;
			height: 20px;
			transform: translateX(-50%) translateY(-50%);
			transition: 0.3s all ease;
		}
	}

	a:hover {
		&:after {
			background-color: var(--rentfetch-gold-pale);
		}
	}
}
